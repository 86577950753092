/**
 * @file standalone/mpp-filter-set.scss
 *
 * \brief Library file - shared styles for mpp filter set
 *
 */

@import '../theme-bootstrap';

.mpp-filter-set {
  border: 1px solid $color-off-black;
  height: 40px;
  width: 100%;
  margin: 0 5%;
  @include breakpoint($medium-up) {
    padding-top: 10px;
    height: 35px;
    margin: 0;
    border: none;
  }
  &__item {
    .mpp-filter-set {
      height: auto;
      width: 100%;
      border: none;
      position: unset;
      margin: 0;
      @include breakpoint($medium-up) {
        border-bottom: 1px solid $light-gray;
      }
    }
    .mpp-sort__container--mobile {
      padding: 20px 0;
      display: flex;
      justify-content: center;
      border-top: 1px solid $light-gray;
      border-bottom: 1px solid $light-gray;
      margin: 20px 0;
      height: auto;
      width: 100%;
      .mpp-sort-menu {
        width: 100%;
        height: 40px;
      }
      @include breakpoint($medium-up) {
        display: none;
      }
    }
  }
  &__question {
    float: $ldirection;
    width: 25%;
  }
  &__choices {
    @include breakpoint($small-down) {
      width: 100%;
      margin-top: 10px;
    }
    float: $ldirection;
    width: 75%;
    .button {
      @include breakpoint($small-down) {
        margin-bottom: 0;
        line-height: 24px;
        font-size: 11px;
        display: block;
        min-width: auto;
        padding: 3px 0 0;
        float: $ldirection;
        width: 20%;
        border-left: 0;
        &:first-child {
          border-left: 1px solid $color-off-black;
        }
      }
      margin-bottom: 0;
      @include breakpoint($portrait-up) {
        margin-bottom: 16px;
      }
      &.button--inline:active,
      &.button--inline:hover,
      &.button--inline:focus {
        background: $white;
        color: $color-off-black;
      }
      &.button--inline.active {
        background: $color-off-black;
        color: $white;
      }
    }
  }
  &__mobile-cta {
    cursor: pointer;
    height: 100%;
    @include breakpoint($medium-up) {
      display: none;
    }
    &-text {
      display: flex;
      justify-content: space-between;
      padding: 0 10px;
      align-items: center;
      height: 100%;
      @if $typography_cr24 == true {
        @include body-r;
      } @else {
        font-size: 15px;
      }
    }
  }
  &__overlay {
    display: none;
    @include breakpoint($medium-down) {
      background: $color-off-black;
      height: 100%;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 10;
    }
  }
  &__container {
    display: none;
    position: fixed;
    background: $white;
    height: 100%;
    top: 0;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    z-index: 17;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    @include breakpoint($medium-up) {
      padding-top: 10px !important;
      display: block !important;
      overflow: visible;
      position: static;
    }
  }
  &__filter-label {
    display: flex;
  }
  &__filter-count {
    padding-#{$ldirection}: 5px;
  }
  &__header {
    display: none;
    @include breakpoint($medium-up) {
      font-size: 15px;
      line-height: 1;
      padding: 0;
      display: flex;
      justify-content: space-between;
    }
  }
  &__sub-header {
    display: flex;
    gap: 45px;
  }
  &__mobile-cta-text--edit {
    display: none;
  }
  &__label {
    display: flex;
    cursor: pointer;
    @include swap-direction(margin, 0 0 25px 40px);
    padding-top: 5px;
    @if $typography_cr24 == true {
      @include body-r;
    } @else {
      line-height: 1;
      font-weight: 500;
      font-size: 15px;
    }
  }
  &__count {
    display: none;
    @if $typography_cr24 == true {
      @include body-r;
    }
    @include breakpoint($medium-up) {
      display: block;
      white-space: nowrap;
      padding-top: 5px;
    }
  }
  &__text {
    display: flex;
    gap: 5px;
  }
  &__body {
    padding: 30px 0 60px;
    @include breakpoint($medium-up) {
      padding: 0;
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 0;
      #{$ldirection}: 0;
      background-color: $white;
      width: 305px;
      z-index: 4;
    }
    &-label {
      color: $color-off-black;
      border: none;
      display: flex;
      padding: 0;
      width: auto;
      justify-content: space-between;
      margin: 0 40px;
      @if $typography_cr24 == true {
        @include body-r;
      } @else {
        font-size: 16px;
        font-weight: 500;
        line-height: 1;
      }
      @include breakpoint($medium-up) {
        margin: 20px 40px 0;
      }
    }
    &-text {
      display: flex;
      gap: 20px;
    }
  }
  &__count-total {
    padding-#{$rdirection}: 2px;
  }
  &__section {
    font-size: 15px;
    margin: 0 40px;
    border-bottom: 1px solid $light-gray;
    &-icon-plus,
    &-icon-minus {
      svg {
        width: 13px;
        height: 13px;
      }
    }
    &-icon-minus {
      display: none;
    }
    &-icon-plus {
      display: block;
    }
  }
  &__title {
    cursor: pointer;
    display: flex;
    margin: 20px 0;
    justify-content: space-between;
    @if $typography_cr24 == true {
      @include body-r-bold;
      text-transform: uppercase;
      @include breakpoint($landscape-up) {
        text-transform: uppercase;
      }
    } @else {
      font-size: 16px;
      font-weight: 500;
    }
    &.filter-active {
      .mpp-filter-set__section-icon-plus {
        display: none;
      }
      .mpp-filter-set__section-icon-minus {
        display: block;
      }
    }
  }
  &__filters {
    display: block;
    padding-bottom: 20px;
    &.active {
      display: inline-block;
    }
    &.hide-filter {
      display: none;
    }
  }
  &__button {
    cursor: pointer;
    display: block;
    position: relative;
    display: flex;
    align-items: center;
    @if $typography_cr24 == true {
      @include body-xs;
      line-height: 1.5;
      @include breakpoint($landscape-up) {
        line-height: 1.5;
      }
    } @else {
      font-size: 15px;
      line-height: 1.5;
    }
    @include breakpoint($medium-up) {
      border: 0;
      padding: 0;
      &:hover {
        color: $color-gray;
        opacity: 1;
      }
      &:last-child {
        padding: 0;
      }
    }
    &::before {
      background: $white;
      border: $color-off-black solid 1px;
      content: '';
      display: inline-block;
      height: 18px;
      width: 18px;
      margin-#{$rdirection}: 7px;
    }
    &.active::after {
      border-#{$rdirection}: 1px solid $color-off-black;
      border-bottom: 1px solid $color-off-black;
      content: ' ';
      display: inline-block;
      position: absolute;
      top: 4px;
      #{$ldirection}: 6px;
      transform: rotate(35deg);
      height: 11px;
      width: 5px;
    }
  }
  &__footer {
    background: $white;
    bottom: 39px;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    padding-bottom: 1em;
    z-index: 1;
    margin-top: 35px;
  }
  &__submit {
    display: none;
  }
  &__reset {
    @include swap-direction(margin, 0 0 35px 40px);
    width: 100px;
    min-height: 45px;
    .mpp-filter-set--filtered & {
      display: block;
    }
  }
  &__close {
    cursor: pointer;
    svg {
      height: 10px;
      width: 10px;
      stroke: $color-off-black;
      stroke-width: 40px;
      @include breakpoint($medium-up) {
        stroke-width: 15px;
      }
    }
    .icon {
      width: 20px;
      height: 20px;
    }
  }
  &__selections {
    display: none;
    font-size: 15px;
    text-align: center;
    padding: 5px 30px 10px;
    clear: both;
    &.active {
      display: block;
      @include breakpoint($medium-up) {
        display: none;
      }
    }
    &-reset {
      color: $color-gray;
      text-decoration: none;
      white-space: nowrap;
      position: relative;
      #{$ldirection}: -10px;
      &:hover {
        text-decoration: none;
      }
      &--desktop {
        text-decoration: none;
        padding-#{$ldirection}: 0;
        display: none;
        @include breakpoint($medium-up) {
          display: block;
          opacity: 0;
          transition: opacity 0.2s;
          .mpp-filter-set--filtered & {
            opacity: 1;
          }
        }
      }
    }
    &-tags {
      text-transform: uppercase;
    }
    &-count {
      padding: 5px 0 0;
    }
  }
  &__recommended {
    font-weight: normal;
    font-size: 15px;
    white-space: nowrap;
    margin-top: 15px;
    @include breakpoint($medium-up) {
      margin-top: 0;
    }
    > input[type='checkbox'] {
      display: none;
    }
    > label {
      text-transform: none;
      font-weight: 500;
      font-size: 15px;
      overflow: hidden;
      line-height: get-line-height(16px, 18px);
      letter-spacing: 0;
    }
    &-tooltip {
      display: inline-block;
      height: 18px;
      width: 18px;
      line-height: get-line-height(15px, 15px);
      text-align: center;
      cursor: pointer;
      font-size: 15px;
    }
    .svgicon {
      @include swap-direction(margin, 2px 10px 0 0);
      width: 18px;
      height: 18px;
      float: $ldirection;
    }
    .svgicon--checkbox2 {
      display: inline-block;
    }
    .svgicon--checkbox2--checked {
      display: none;
    }
    &.checked {
      .svgicon--checkbox2 {
        display: none;
      }
      .svgicon--checkbox2--checked {
        display: inline-block;
      }
    }
  }
  &__background-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    bottom: 0;
    background-color: $color-off-black;
    opacity: 0.5;
    cursor: pointer;
    padding: 0;
    z-index: 2;
  }
  &__filter-icon {
    width: 23px;
    height: 19px;
    position: relative;
    color: $color-off-black;
    flex-wrap: nowrap;
    order: 1;
    display: block;
    .line1,
    .line2 {
      width: 15px;
      top: 10px;
      #{$ldirection}: 0;
      transition: all 0.5s ease;
      position: absolute;
      border-bottom: 1.5px solid $color-off-black;
      @include breakpoint($medium-up) {
        width: 23px;
        border-bottom: 1px solid $color-off-black;
      }
      &::before {
        width: 4px;
        height: 4px;
        background-color: $color-off-black;
        content: '';
        display: inline-block;
        position: absolute;
        top: -1px;
        border-radius: 5px;
        #{$ldirection}: 25%;
        @include breakpoint($medium-up) {
          width: 3px;
          height: 3px;
          border-radius: 4px;
          top: -1px;
          #{$ldirection}: 22%;
        }
      }
      @include breakpoint($medium-up) {
        width: 15px;
      }
    }
    .line1 {
      transform: none;
      margin-top: 3px;
      @include breakpoint($medium-up) {
        margin-top: 2.5px;
      }
    }
    .line2 {
      transform: none;
      margin-top: -3px;
      &::before {
        #{$ldirection}: auto;
        #{$rdirection}: 25%;
        @include breakpoint($medium-up) {
          #{$rdirection}: 22%;
        }
      }
    }
    @include breakpoint($medium-up) {
      width: 15px;
      margin-#{$ldirection}: 12px;
      margin-top: 1px;
    }
  }
  .mpp-filter-set__item & {
    padding: 16px 10px 0;
  }
}

.tooltipster-aveda {
  .section-products & {
    border-radius: 5px;
    font-size: 12px;
    line-height: get-line-height(12px, 15.41px);
  }
}
